<template>
  <v-card class="overflow-hidden">
    <v-app-bar dense elevation="0" absolute fixed>
      <v-row align="center">
        <v-toolbar-title>Customers: {{ customersTotal }} fetched</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select v-model="searchFieldName" dense row class="shrink mr-2 mt-0"
                  label="Search in" solo :items="searchFields">
        </v-select>
        <v-text-field v-model="searchField" prepend-inner-icon="mdi-magnify" label="Search" single-line
                      hide-details dense
                      @keyup.enter.native=newSearch></v-text-field>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small left :loading="isLoading" @click="newSearch" v-bind="attrs" v-on="on">
              <v-icon>mdi-run</v-icon>
            </v-btn>
          </template>
          <span>Run a new search with given search criteria.</span>
        </v-tooltip>
      </v-row>
    </v-app-bar>
    <v-sheet class="overflow-y-auto" :height="customerAreaSizeY" v-resize="onResize">
      <v-container v-show="snackbar===true" dense no-gutters style="position: absolute; top:50px">
        <v-snackbar v-model="snackbar" timeout="8000" :absolute=true>
          {{ snackbarMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn depressed small v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
      <v-container id="customer-area" class="overflow-y-auto" fluid style="position: relative; top:40px">
        <v-data-table
            dense :headers="headers"
            :items="customers"
            :options.sync="options"
            :footer-props="{'items-per-page-options':[10, 15, 30, 60, 100, -1]}"
            :items-per-page="30"
            :server-items-length="customersTotal"
            sort-by="created_at"
            :sort-desc="true"
            :loading="isLoading"
            :fixed-header="true"
            :height="tableHeight"
            class="elevation-1"
            :search="options.search"
            @update:options="onPageChanged"
        >
          <template v-slot:no-data>
            No customers
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="showItem(item)">mdi-eye</v-icon>
          </template>
        </v-data-table>
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  name: "OrderPage",
  components: {},
  data() {
    return {
      headers: [ // table headers
        {text: 'Email', value: 'id'},
        {text: 'First Name', value: 'first_name'},
        {text: 'Last Name', value: 'last_name'},
        {text: 'City', value: 'city'},
        {text: 'Country', value: 'country'},
        {text: 'Orders', value: 'total_orders'},
        {text: 'Spent', value: 'total_spent'},
        {text: 'Marketing', value: 'marketing'},
      ],
      searchField: "",
      options: {
        page: 0, // set to 0 when page loaded
        sortBy: ["id"],
        sortDesc: [false],
      },
      isLoading: false,
      orderFilter: {},
      customerAreaSizeY: 0, // the area y size for displaying orders
      tableHeight: 0, // table height
      snackbar: false,
      snackbarMsg: '',
      searchFields: [
        {text: 'Date', value: 'created_at'},
        {text: 'Email', value: '_id'},
        {text: 'Last Name', value: 'last_name'},
        {text: 'First Name', value: 'first_name'},
        {text: 'Country', value: 'country'}],
      searchFieldName: '',
    }
  },
  computed: {
    customers() {
      return this.$store.getters['customerStore/getCustomers']
    },
    customersTotal() {
      return this.$store.getters['customerStore/getCustomersTotal']
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch('customerStore/clearCustomers');
      this.isLoading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options
      let args = {}
      if (this.searchField != "" && this.searchFieldName != "") {
        args.filter = {
          field: this.searchFieldName,
          value: this.searchField,
        }
      }
      args.limit = itemsPerPage > 0 ? itemsPerPage : 0
      args.skip = (page - 1) * args.limit
      if (sortBy) {
        args.sort = sortBy[0]
        if (args.sort == "id") {
          args.sort = "_id"
        }
        args.sort_desc = sortDesc[0]
      }
      return this.$store.dispatch('customerStore/fetchCustomers', args).then(() => {
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    newSearch() {
      console.log("Search field=" + this.searchFieldName + " search for " + this.searchField)
      this.options.page = 1
      this.options.search = this.searchField
      this.loadData()
    } ,
    onPageChanged({page}) {
      if (page > 0)
        this.loadData()
    },
    onResize() {
      this.customerAreaSizeY = window.innerHeight - 110
      this.tableHeight = window.innerHeight - 240
    }
    ,
    showItem(item) {
      let index = this.customers.indexOf(item)
      alert("Customer-id=" + this.customers[index]["_id"])
    },
  },
  created() {
  },
  watch: {
    options: {
      handler() {
        //this.loadData()
      },
      deep: true,
    },
  },
  mounted() {
    this.onResize()
    this.page = 1
    this.$store.dispatch('customerStore/clearCustomers')
  },
}
</script>

<style scoped>

</style>
